<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-end">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input
          :value="searchQuery"
          @input="(val) => $emit('update:searchQuery', val)"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
      </b-input-group>
    </div>
  </div>
</template>

<script>
// Options
import StaticOptions from '@/common/options/StaticOptions'

export default {
props: {
  searchQuery: {
    type: String,
    default: null,
  },
},
data() {
  return {
  }
},
}
</script>
