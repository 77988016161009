import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import usePagination from '../../pagination/usePagination'

export default function usePresetEthnicityList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'ethnicName', sortable: true },
    { key: 'orderNumber', sortable: true },
    { key: 'actions', class: 'text-right' },
  ]

  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    refListTable.value.refresh()
  }
  
  watch([searchQuery], () => {
    refetchData()
  })

  const { perPage, currentPage, totalItems, dataMeta, perPageOptions } = usePagination(refetchData)

  const fetchList = (ctx, callback) => {
    store
      .dispatch('presetEthnicityManagement/fetchPresetRegionEthnicities', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { data, count } = response.result;
        
        callback(data)
        totalItems.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Preset Ethnicity list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchList,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
  
    refetchData,
  }
}
