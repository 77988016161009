import { ref, computed, watch } from '@vue/composition-api'

export default function usePagination(refetchFunction) {
  const perPage = ref(10)
  const currentPage = ref(1)
  const totalItems = ref(0)
  const perPageOptions = [10, 25, 50, 100]

  const dataMeta = computed(() => {
    const from = perPage.value * (currentPage.value - 1) + 1
    const to = Math.min(perPage.value * currentPage.value, totalItems.value)
    return {
      from,
      to,
      of: totalItems.value,
    }
  })

  watch([currentPage, perPage], () => {
    refetchFunction()
  })

  return {
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
  }
}
