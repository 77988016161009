<template>
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <b-form-group class="mb-0">
        <b-form-select
          v-model="localPerPage"
          :options="perPageOptions"
          class="per-page-selector"
        />
      </b-form-group>
      <span class="text-muted ml-2">
        Showing {{ meta.from }} to {{ meta.to }} of {{ meta.of }} entries
      </span>
    </div>

    <b-pagination
      :value="localCurrentPage"
      :total-rows="totalItems"
      :per-page="perPage"
      @input="handlePageChange"
      first-number
      last-number
      class="mb-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    perPageOptions: {
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    currentPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localCurrentPage = ref(props.currentPage)
    const localPerPage = ref(props.perPage)

    // Watch for changes in the parent-provided currentPage and perPage props
    watch(() => props.currentPage, (newVal) => {
      localCurrentPage.value = newVal
    })

    watch(() => props.perPage, (newVal) => {
      localPerPage.value = newVal
    })

    // Emit the updated page number when pagination changes
    const handlePageChange = (newPage) => {
      emit('update:currentPage', newPage)
    }

    // Emit the updated perPage value when the user changes the page size
    watch(localPerPage, (newVal) => {
      emit('update:perPage', newVal)
    })

    return {
      localCurrentPage,
      localPerPage,
      handlePageChange,
    }
  },
}
</script>
