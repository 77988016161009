export default {

  userRoleOptions: [
    { label: 'Admin', value: 1 },
    { label: 'TraitsAndHealth', value: 2 },
  ],

  userStatusOptions: [
    { label: 'Personel', value: 1 },
    { label: 'Subscriber', value: 2 },
    { label: 'Customer', value: 3 },
  ],

  sourceTypeOptions: [
    { label: 'Periodical/Region', value: 1 },
    { label: 'PCA/Region', value: 5 },
    { label: 'Modern Proximity', value: 2 },
    { label: 'Ancient Proximity', value: 3 },
    { label: 'Ancient Mixed Mode', value: 4 },
    { label: 'Modern Mixed Mode', value: 7 },
    { label: 'Farmer & HGs', value: 6 },
    { label: 'Custom Ancestry Break Down', value: 8 },
    { label: 'Custom Modern Break Down', value: 9 },
  ],

  productTypeOptions: [
    { label: 'Raw Data', value: 1 },
    { label: 'Coordinate', value: 2 },
    { label: 'TraitsAndHealth', value: 3 },
    { label: 'IBDAnalysis', value: 4 },
  ],

  orderStatusOptions: [
    { label: 'Pending', value: 1 },
    { label: 'Processing', value: 2 },
    { label: 'Completed', value: 3 },
    { label: 'Cancelled', value: 4 },
  ],

  orderCancelReasonOptions: [
    { label: 'NONE', value: 0 },
    { label: 'INVALID_FILE_UPLOAD', value: 1 },
    { label: 'CUSTOMER_CHANGE_MIND', value: 2 },
    { label: 'ORDER_BY_MISTAK', value: 3 },
    { label: 'PAYMENT_FAILURE', value: 4 },
  ],

  orderResultStatusOptions: [
    { label: 'Pending', value: 1 },
    { label: 'Completed', value: 2 },
    { label: 'Waiting Mixed Mode', value: 3 },
    { label: 'Waiting File Upload', value: 4 },
    { label: 'Processing', value: 5 },
  ],

  orderResultTraitStatusOptions: [
    { label: 'Pending', value: 1 },
    { label: 'Completed', value: 2 },
  ],

  paymentTypeOptions: [
    { label: 'Direct Bank', value: 1 },
    { label: 'Card', value: 2 },
  ],

  ticketFileTypeOptions: [
    'image/apng',
    'image/png',
    'image/jpg',
    'image/jpeg',
  ],

  orderPromotionDiscountOptions: [
    { label: 'PERCENT', value: 1 },
    { label: 'UNIT', value: 2 },
  ],

  orderPromotionOptions: [
    { label: 'GENERAL', value: 1 },
    { label: 'CUSTOMER', value: 2 },
  ],

  traitCategoryTypes: [
    { label: 'TRAITS', value: 1 },
    { label: 'VITAMINS AND MINERALS ', value: 2 },
  ],

  traitRates: [
    { label: 'Low', value: 1 },
    { label: 'Intermediate', value: 2 },
    { label: 'High', value: 3 },
  ],
}
